<script>
import { h, provide, ref } from 'vue'
import descTemplate from './components/desc-template.vue'
import templateGroup from '../components/template-group.vue'
import { ElTabs, ElTabPane } from 'element-plus'
import type from '@/common/utils/type'
export default {
  setup () {
    const descTemplateCom = ref(null)
    const tabs = {
      activeName: 'descTemplate',
      tabs: [
        { label: '图文模板', name: 'descTemplate', component: descTemplate, ref: descTemplateCom },
        { label: '模板类型', name: 'templateGroup', component: templateGroup }
      ]
    }
    provide('reloadTemplate', () => descTemplateCom.value.searchData())

    return {
      tabs
    }
  },
  render () {
    return h(
      ElTabs, {
        modelValue: this.tabs.activeName
      }, () => this.tabs.tabs.map(item => {
        return h(ElTabPane, {
          name: item.name,
          label: item.label
        }, () => [
          h(item.component, {
            ref: item.ref || '',
            type: type().templateGroupType.desc
          })
        ])
      })
    )
  }
}
</script>
<style lang="scss" scoped>
</style>
