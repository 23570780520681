<template>
  <!-- table -->
  <xm-flex>
    <xm-flex-item>
      <xm-search-box>
        <el-select v-model="search.templateGroupId" placeholder="请选择类型" size="small" @change="searchData" clearable>
          <el-option v-for="item in selector.templateGroup" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-button size="small" type="primary" @click="add">新增模板</el-button>
      </xm-search-box>
    </xm-flex-item>
    <xm-flex-item><xm-total-count>{{`图文列表(${totalCount}条)`}}</xm-total-count></xm-flex-item>
    <xm-flex-item :flex="1">
      <xm-table-plus :query-data="queryData" ref="table">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="templateGroup.name" label="类型" width="150"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column label="状态" width="70">
          <template #default="scope">
            <el-tag type="primary" size="small" v-if="scope.row.flag === 1">显示</el-tag>
            <el-tag type="info" size="small" v-else>隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="210">
          <template #default="scope">
            <el-button type="text" size="small" @click="modify(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="edit(scope.row.id)">编辑模板</el-button>
            <el-button type="text" size="small" @click="setFlag(scope.row.id, scope.row.flag)">{{scope.row.flag===1?'隐藏':'显示'}}</el-button>
            <el-dropdown @command="handleCommand">
              <el-button type="text" size="small" class="el-dropdown-link">更多</el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'up'}" v-if="!scope.row._isFirst">上移</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'down'}" v-if="!scope.row._isLast">下移</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'top'}" v-if="!scope.row._isFirst">置顶</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'bottom'}" v-if="!scope.row._isLast">置底</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'clear', id: scope.row.id}">删除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </xm-table-plus>
    </xm-flex-item>
  </xm-flex>
  <!-- dialog -->
  <el-dialog v-model="dialog.visible" :title="dialog.title" destroy-on-close>
    <el-form ref="ruleForm" :model="dialog.data" label-width="auto">
      <!-- 名称 -->
      <el-form-item label="名称" prop="name" :rules="[
        {required:true, message: '名称不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.name" size="small" maxlength="128" clearable></el-input>
      </el-form-item>
      <!-- 类型 -->
      <el-form-item label="类型" prop="templateGroupId" :rules="[
        {required:true, message: '类型不能为空',trigger: 'blur'}
      ]">
        <el-select v-model="dialog.data.templateGroupId" placeholder="请选择类型" size="small" clearable>
          <el-option v-for="item in selector.templateGroup" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dialog.visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="confirm" size="small">确定</el-button>
    </template>
  </el-dialog>
  <!-- drawer -->
  <el-drawer v-model="drawer.visible" title="编辑模板" :size="500" :before-close="beforeClose" destroy-on-close>
    <xm-flex>
      <xm-flex-item :flex="1">
        <editor v-model="drawer.content" :init="drawer.setting" />
      </xm-flex-item>
      <xm-flex-item class="button-group">
        <el-button @click="beforeClose" size="small">取消</el-button>
        <el-button type="primary" @click="saveContent" size="small">确定</el-button>
      </xm-flex-item>
    </xm-flex>
  </el-drawer>
</template>
<script>
import { ElButton, ElDrawer, ElDialog, ElSelect, ElOption, ElTableColumn, ElForm, ElFormItem, ElInput, ElDropdown, ElDropdownItem, ElDropdownMenu, ElTag } from 'element-plus'
import axios from '@/common/utils/axios'
import { onMounted, reactive, ref } from 'vue'
import Editor from '@tinymce/tinymce-vue'
import tinymce from 'tinymce/tinymce'
import setting from '@/common/js/graphic-editor.js'
import type from '@/common/utils/type'

function table () {
  const search = reactive({
    templateGroupId: ''
  })
  const selector = reactive({
    templateGroup: []
  })
  /**
     * 表单信息
     */
  const table = ref(null)
  const totalCount = ref(0)
  /**
     * 查询表单列表
     */
  const queryData = (page, pageSize) => {
    return new Promise((resolve) => {
      axios('/manager/desc/template/list', {
        templateGroupId: search.templateGroupId,
        page,
        pageSize
      })
        .then(res => {
          totalCount.value = res.totalCount
          table.value.judgePosition(res.descTemplateList, res.totalCount)
          resolve({
            list: res.descTemplateList,
            totalCount: res.totalCount
          })
        }).catch(_ => { })
    })
  }
  /**
   * 搜索模板
   */
  const searchData = () => {
    table.value.reload()
  }
  /**
     * 查询枚举--模板类型
     */
  const queryType = () => {
    return new Promise((resolve) => {
      axios('/manager/template/group/list/all', { type: type().templateGroupType.desc })
        .then(res => {
          res.templateGroupList.forEach(item => {
            selector.templateGroup.push(item)
          })
          search.templateGroupId = res.templateGroupList[0].id
          resolve()
        }).catch(_ => { })
    })
  }
  onMounted(async () => {
    await queryType()
    table.value.reload()
  })

  return {
    search,
    selector,
    table,
    queryData,
    totalCount,
    searchData
  }
}

function dialog () {
  const ruleForm = ref(null)
  const dialog = reactive({
    visible: false,
    title: '',
    data: {}
  })
  return {
    ruleForm,
    dialog
  }
}
function drawer () {
  const drawer = reactive({
    id: null,
    visible: false,
    content: '',
    contentCopy: '',
    setting: {
      ...setting,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        setting.images_upload_handler(blobInfo, success, failure, progress, '0', imageCb)
      },
      file_picker_callback: (callback, value, meta) => {
        setting.file_picker_callback(callback, value, meta, '0', videoCb)
      }
    }
  })

  // 图片上传成功回调
  const imageCb = (url) => {
    axios('/manager/desc/template/image/save', {
      descTemplateId: drawer.id,
      url: url
    }).then(() => { }).catch(_ => { })
  }

  // 视频上传成功回调
  const videoCb = (url) => {
    axios('/manager/desc/template/video/save', {
      descTemplateId: drawer.id,
      url: url
    }).then(() => { }).catch(_ => { })
  }

  const beforeClose = () => {
    if (drawer.content === drawer.contentCopy) {
      drawer.visible = false
      return
    }
    window.$confirm('是否放弃编辑').then(() => {
      drawer.visible = false
    }).catch(_ => { })
  }
  /**
   * 保存模板
   */
  const saveContent = () => {
    axios('/manager/desc/template/text/save', {
      descTemplateId: drawer.id,
      text: drawer.content
    })
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        drawer.visible = false
      }).catch(_ => { })
  }

  return {
    drawer,
    beforeClose,
    saveContent
  }
}

function operation (t, d, dr) {
  /**
     * 新增
     */
  const add = () => {
    d.dialog.visible = true
    d.dialog.title = '新增模板'
    d.dialog.data = {
      descTemplateId: '',
      templateGroupId: t.search.templateGroupId,
      name: ''
    }
  }
  /**
     * 修改
     */
  const modify = (row) => {
    d.dialog.visible = true
    d.dialog.title = '修改模板'
    d.dialog.data = {
      descTemplateId: row.id,
      templateGroupId: row.templateGroup.id,
      name: row.name
    }
  }
  /**
   * 保存图文模板
   */
  const confirm = () => {
    d.ruleForm.value.validate(valid => {
      if (!valid) {
        return
      }
      submit()
    })
  }
  const submit = () => {
    axios('/manager/desc/template/save', d.dialog.data)
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        d.dialog.visible = false
        t.table.value.reloadCurrent()
      }).catch(_ => { })
  }
  /**
     * 编辑模板
     */
  const edit = async (id) => {
    dr.drawer.id = id
    dr.drawer.content = await queryContent(id)
    dr.drawer.contentCopy = dr.drawer.content
    dr.drawer.visible = true
    tinymce.init({})
  }
  const queryContent = (id) => {
    return new Promise((resolve) => {
      axios('/manager/desc/template/text', {
        descTemplateId: id
      })
        .then(res => {
          resolve(res.text)
        }).catch(_ => { })
    })
  }
  /**
   * 更改状态
   */
  const setFlag = (id, flag) => {
    axios('/manager/desc/template/flag/set', {
      descTemplateId: id,
      flag: flag === 1 ? 0 : 1
    }).then(res => {
      window.$message({
        message: '操作成功',
        type: 'success'
      })
      t.table.value.reloadCurrent()
    })
  }
  /**
     * 指令列表
     */
  const handleCommand = (command) => {
    commandList[command.name](command.id, command.action)
  }
  const commandList = {
    clear: (id) => { // 删除
      window.$confirm('是否确认删除').then(() => {
        del(id)
      }).catch(_ => { })
    },
    move: (id, action) => {
      axios('/manager/desc/template/move', {
        descTemplateId: id,
        move: action
      }).then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        t.table.value.reload()
      })
    }
  }
  const del = (id) => {
    axios('/manager/desc/template/del', {
      descTemplateId: id
    })
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        t.table.value.reloadCurrent()
      }).catch(_ => { })
  }
  return {
    add,
    modify,
    confirm,
    edit,
    setFlag,
    handleCommand
  }
}

export default {
  components: { Editor, ElButton, ElDrawer, ElDialog, ElSelect, ElOption, ElTableColumn, ElForm, ElFormItem, ElInput, ElDropdown, ElDropdownItem, ElDropdownMenu, ElTag },

  setup () {
    const t = table()
    const d = dialog()
    const dr = drawer()
    const o = operation(t, d, dr)
    return {
      ...t,
      ...d,
      ...dr,
      ...o
    }
  }
}
</script>
<style lang="scss" scoped>
.button-group {
  text-align: right;
}
:deep .tox-tinymce{
  height: calc(100% - 20px) !important;
}
.el-dropdown .el-button {
  margin-left: 10px;
}
</style>
